import React, {Component} from "react";
import ReactDOM from "react-dom";
import RecipeFilterMenu from "./RecipeFilterMenu";
import ChipMenu from "./ChipMenu";
import SearchOverride from "./SearchOverride";
import RecipeWidget from "./RecipeWidget";
import Pagination from "./Pagination";
import RecipeList from "./RecipeList";

class Page extends Component{
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {...props, ...{api:this}};
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
    componentDidMount() {
        this._isMounted = true;
        if(this.state.sort || this.state.category || this.state.time) {
            this.state.widgets.right = [(this.state.paging.count || this.state.paging.pageCount) + ' Recipes'];
        }
        this.loadElements();
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState !== this.state) {
            if(this.state.sort || this.state.category || this.state.time) {
                this.state.widgets.right = [(this.state.paging.count || this.state.paging.pageCount) + ' Recipes'];
            } else {
                this.state.widgets.right = null;
            }
        }
        this.loadElements();
    }


    fetchPage(api_url = null) {
        api_url = api_url || this.recipesApiUrl();
        fetch(
            api_url,
            {headers: { 'Accept': 'application/json', 'Content-Type': 'application/json',}}
        )
            .then(res => res.json())
            .then((data) => {
                if (this._isMounted) {
                    this.setState(this.updateStatus(data));
                }
            })
            .catch(console.log);
        console.log(this.state);
    }

    recipesApiUrl(page = this.state.paging.page || "") {
        let properties = {};
        if (page) {
            properties.page = page;
        }
        if (this.state.time) {
            properties.time = this.state.time;
        }
        if (this.state.checked.length) {
            properties.category = this.state.checked.join(',')
        }
        if (this.state.search) {
            properties.searchString = this.state.search;
        }
        if (this.state.sort) {
            properties.sort = this.state.sort;
        }
        let queryString = Object.keys(properties).map(key => key + '=' + properties[key]).join('&');
        return this.state.apiUrl + "?" + queryString;
    }

    updateStatus(statusData) {
        let origin_page = this.state.paging?this.state.paging.page: statusData.paging.page;
        console.log(this.state, statusData);
        this.setState(statusData);
        Object.assign(this.state, statusData);

        console.log("New State:", this.state);

        if (this.state.searchString) {
          if (typeof gtag === 'function') {
            gtag('event', 'view_recipe_search_results', {
              send_to: 'G-P30JN1M27B',
              property: 'Sous Vide Recipe Site',
              search_string: this.state.searchString,
              result_count: this.state.paging.count,
              page_url: window.location.href
            });
          }
        }

        if (this.state.checked.length) {
          const numberToCollection = {
            1: 'Beef',
            2: 'Cocktails & Beverages',
            3: 'Desserts',
            4: 'Eggs',
            5: 'Fish and Seafood',
            6: 'Fruits',
            7: 'Game',
            8: 'Lamb',
            9: 'Pork',
            10: 'Poultry',
            11: 'Sauces and Condiments',
            12: 'Soups',
            13: 'Vegetables',
            14: 'Other'
          };

          const filteredCollection = this.state.checked.map(number => numberToCollection[number]).join(', ');

          if (typeof gtag === 'function') {
            gtag('event', 'view_recipe_collection', {
              send_to: 'G-P30JN1M27B',
              property: 'Sous Vide Recipe Site',
              filtered_collection: filteredCollection,
              result_count: this.state.paging.count,
              page_url: window.location.href
            });
          }
        }

        return {recipes: this.state.recipes, paging: this.state.paging};
    };

    loadElements() {
        ReactDOM.render(
            <RecipeFilterMenu api = {this}  options = {data} onPageRequest = {this.updateStatus.bind(this)}
                              onSelectOptions = {this.updateStatus.bind(this)} />,
            document.querySelector('#filterMenu'));
        ReactDOM.render(<ChipMenu
                onPageRequest = {this.updateStatus.bind(this)}
                categories={data}
                search={this.state.api.state.search}
                time={this.state.api.state.time}
                chips={this.state.api.state.checked} api={this.state.api} onRemoveChip={this.state.api.fetchPage.bind(this.state.api)}/>,
            document.querySelector('#chipMenu'));
        ReactDOM.render(
            <SearchOverride api = {this}
                            search={this.state.api.state.search}
                            onSelectOptions={this.state.api.fetchPage.bind(this.state.api)} />,
            document.querySelector('.search'));
        ReactDOM.render(
            <RecipeWidget key='sorting' paging={this.state.paging} checked={this.state.checked} api={this}
                          widgets={this.state.widgets} onPageRequest = {this.updateStatus.bind(this)}
            />,
            document.querySelector('.recipe-list-widgets')
        );
        ReactDOM.render(
            <RecipeList api={this.state.api} recipes={this.state.recipes} />,
            document.querySelector('.recipe-list-wrapper'));
        ReactDOM.render(
            <Pagination paging={this.state.paging} checked={this.state.checked} search={this.state.search}
                        onPageRequest = {this.updateStatus.bind(this)} api={this} />,
            document.querySelector('#pagination')
        );
    }
    render() {
        return (<div className={"page"} />);
    }
}
Page.defaultProps = {
    apiUrl: '',
    paging: {page:1}, // {page: 1, pageCount: pagesCount},
    search: '',
    recipes: [],
    checked: [],
    widgets: {
        // 'left': {
        //     'recent_views': 'Views',
        //     'avg_rating': 'Ratings',
        //     'favorites': 'Favorites'
        // },
        //'right': [(paging.count||paging.pageCount)+' Recipes']
    }
};
export default Page;

if(document.querySelector('#flag-recipe')) {
    document.querySelector('#flag-recipe').addEventListener('click', function(event) {
        flagRecipe(event.currentTarget.getAttribute('data-id'));
    });
}

function flagRecipe(recipeID) {
    $.post('/recipe/flag/' + recipeID)
    .fail(function(error) {        
        alert('There was a problem flagging this recipe');
    })
    .done(function(response) {        
        var cookie_value;
        if(Cookies.get('flagged')) {
            cookie_value = JSON.parse(Cookies.get('flagged'));
            cookie_value.push(parseInt(recipeID));
            cookie_value = JSON.stringify(cookie_value);
            Cookies.set('flagged', cookie_value, {expires: 15, path: ''});        
        } else {
            cookie_value = JSON.stringify([recipeID]);
            Cookies.set('flagged', cookie_value, { expires: 15, path: '' });
        }
        updateFlagDropdown();
    })
}

function updateFlagDropdown() {
    $('.recipe-toolbar-action-flag').addClass('open');
    $('.recipe-toolbar-action-flag i').removeClass('fa-flag-o').addClass('fa-flag');
    $('.flag-dropdown p').text('This recipe is flagged for review.');
    $('.flag-dropdown a').remove();    
    $('.flag-dropdown').css({'height': 'auto', 'width': '140px'});
}

function alreadyFlagged() {
    $('.flag-dropdown p').text('This recipe is flagged for review.');
    $('.flag-dropdown a').remove();    
    $('.flag-dropdown').css({'height': 'auto', 'width': '140px'});
}

import React, {Component} from "react";
import PropTypes from "prop-types";

class DropdownButton extends Component {
    constructor(props) {
        super(props);
        this.container = React.createRef();
        this.toggleClass = this.toggleClass.bind(this);
        this.onClickOutsideHandler = this.onClickOutsideHandler.bind(this);
        this.onApplyAll = this.onApplyAll.bind(this);
        let extra_state = {};
        if (this.props.time) {
            [extra_state.min, extra_state.max] = this.props.time.split('-');
        }
        this.state = {
            ...this.props.api.state,
            ...{
                dropDownValue: [],
                active: false
            },
            ...extra_state
        };
    }
    componentDidUpdate(prevProps, prevState) {
        if (!this.props.time && prevProps.time) {
            console.log("Min", this.state.min, "max", this.state.max);
            min.value = null;
            max.value = null;
        }
    }
    checkEnter(event) {
        if (event.key === "Enter") {
            this.onApplyAll();
            event.preventDefault();
        }
    }
    onApplyAll() {
        this.setState({warning:null}, this.validateRangeValues);
        console.log('Warnings: ', !this.state.warning);
        if (!this.state.warning || this.state.warning.length <= 0) {
            this.state.api.setState(
                {time: this.state.min+'-'+this.state.max},
                () => {
                    this.props.onSelectOptions()
                });
        }
    };
    toggleClass() {
        const currentState = this.state.active;
        this.setState({ active: !currentState });
    };
    componentDidMount() {
        window.addEventListener('click', this.onClickOutsideHandler);
    }
    componentWillUnmount() {
        window.removeEventListener('click', this.onClickOutsideHandler);
    }
    onClickOutsideHandler(event) {
        if (this.state.active && !this.container.current.contains(event.target)) {
            this.setState({ active: false });
        }
    }
    checkBox(value, condition) {
        // index position can change
        let index = this.state.checked.indexOf(value);
        if(index >= 0) {
            this.state.checked.splice(index, 1);
        } else {
            this.state.checked.push(value);
        }
        this.props.onSelectOptions();
    }
    checklist() {
        const list = this.props.options ? this.props.options.map((data, index) =>
            <label className="container" key={index}>{data.name}
                <input type="checkbox" value={data.value}
                       onChange={e => this.checkBox(Number(e.target.value), e.target.checked)}
                       checked = {this.state.checked.includes(data.value)} />
                <span className="checkmark"></span>
            </label>
        ) : null;
        return(
            <div className="list-result">
                {list}
            </div>
        );
    }
    validateRangeValues() {
        let warnings = [];
        console.log("min", this.state.min, "max", this.state.max, Number(this.state.min) < Number(this.state.max));
        if (Number.isNaN(this.state.min)) {
            warnings.push("Min is not a Number ");
        }
        if (Number.isNaN(this.state.max) && this.state.max !== 'Any') {
            warnings.push("Max is not a Number ");
        }
        if (Number(this.state.min) >  Number(this.state.max) && this.state.max !== 'Any') {
            warnings.push("Invalid Range ");
        }
        this.setState({warning:warnings});
    }
    updateRangeField(label, value) {
        let catch_max = {};
        let range_val = (label === 'max' && Number(value) === 0)
            ?"Any"
            :Number(value);
        if (label === 'max' && !this.state.min) {
            catch_max['min'] = 0;
        }
        if (label === 'min' && !this.state.max) {
            catch_max['max'] = 'Any';
        }
        this.setState(
            {
                [label]: range_val,
                ...catch_max
            },
            this.validateRangeValues
        );
    }
    range() {
        return(
            <div className={this.props.label+"Select"}>
                <div className={"min"+this.props.label}>
                    <label htmlFor="min">Min</label>
                    <input id="min" type="text"
                           onChange={(e) => this.updateRangeField('min', e.target.value)}
                           onKeyPress={(e) => this.checkEnter(e)}
                           placeholder="e.g., 1.5"/>
                </div>
                <div className={"max"+this.props.label}>
                    <label htmlFor="max">Max</label>
                    <input id="max" type="text"
                           onChange={(e) => this.updateRangeField('max', e.target.value)}
                           onKeyPress={(e) => this.checkEnter(e)}
                           placeholder="e.g., 36"/>
                </div>
            </div>
        )
    }
    render() {
        return (
            <div className="multiSelect">
                <div className="searchContainer" ref={this.container}>
                    <div
                        onClick={this.toggleClass}
                        type="button" name={this.props.label}
                        id={this.props.label + "Box"} placeholder={this.props.label}
                        className={(this.state.active) ? "expand input-box": "input-box"} />
                    <div className="fake-text">{this.props.label}</div>
                    <div className="fake-menu" />
                    <div className="search-result">
                        {typeof this.props.onApply === 'function' &&
                        // currently only applies to ranges
                        <div className="applyButton"
                             onClick={() => this.onApplyAll()} >
                            Apply</div>
                        }
                        <div className="description">{this.props.description}</div>
                        <div className="warning">{this.state.warning}</div>
                        {this[this.props.type]()}
                    </div>
                </div>
            </div>
        )
    }
}
/** define proptypes including fields which is required */
DropdownButton.prototypes = {
    options: PropTypes.array,
    checked: PropTypes.array,
    onSelectOptions: PropTypes.func
};

DropdownButton.defaultProps = {
    options: []
};

export default DropdownButton;
import React, {Component} from "react";
import DropdownButton from "./DropdownButton";

class RecipeFilterMenu extends Component {
    constructor(props) {
        super(props);
        this.state = this.props.api.state
    }
    componentDidMount() {
        this.setState(this.state.api.state); //, this.props.api.state);
    }
    render() {
        return (
            <div className='RecipeCategoryFilter RecipeFilterMenu'>
                <DropdownButton label = "Category" type="checklist" options={this.props.options} api = {this.state.api}
                                onSelectOptions={this.state.api.fetchPage.bind(this.state.api)} />
                <DropdownButton
                    label = "Time" type="range" description = "Enter cooking time range in hours."
                    time={this.state.api.state.time}
                    options={this.props.options} api = {this.state.api}
                    // onApply={this.state.api.fetchPage.bind(this.state.api)}
                    onApply={this.state.api.fetchPage.bind(this.state.api)}
                    onSelectOptions={this.state.api.fetchPage.bind(this.state.api)} />
            </div>
        );
    }
}

RecipeFilterMenu.defaultProps = {
    baseUrl: '',
    endpoint:'',
    options: []
};

export default RecipeFilterMenu;
require('jquery-mousewheel');
$.fn.select2 = require('select2');
require('bootstrap-star-rating');

function deleteEntity(entityName) {
    event.stopPropagation();
    event.preventDefault();
    if (confirm('Are you sure you wish to delete this ' + entityName.toLowerCase() + '?')) {
        $.post(event.currentTarget.href, {}, function (data) {
            if (data.status == 'OK') {
                document.location.replace(document.location.href);
            } else {
                alert(entityName.charAt(0).toUpperCase() + entityName.substr(1) + ' not deleted!');
            }
        }, 'json')
    }

    return false;
}

function publishRecipe() {
    return ajaxFieldRecipeHandler(event, 'Are you sure you wish to publish this recipe?', 'Recipe not published!')
}

function unpublishRecipe() {
    return ajaxFieldRecipeHandler(event, 'Are you sure you wish to unpublish this recipe?', 'Recipe not hide!')
}

function featureRecipe() {
    return ajaxFieldRecipeHandler(event, 'Are you sure you wish to feature this recipe?', 'Recipe not feature!')
}

function unfeatureRecipe() {
    return ajaxFieldRecipeHandler(event, 'Are you sure you wish to unfeature this recipe?', 'Recipe not unfeatured!')
}

function ajaxFieldRecipeHandler(event, confirmMessage, errorMessage) {
    event.stopPropagation();
    event.preventDefault();
    if (confirm(confirmMessage)) {
        $.post(event.currentTarget.href, {}, function (data) {
            if (data.status == 'OK') {
                document.location.replace(document.location.href);
            } else {
                alert(errorMessage);
            }
        }, 'json');
    }

    return false;
}

function SetFBCommentNotification(obj) {
    $.ajax({
        url: '/notifications/facebookcomment?key=3nJy8A2h5V6BSb1jAU9mk1DVuZLV0YQBKYurrS',
        data: obj,
        type: 'post',
        dataType: 'json',
        success: function (result) {
            // logs
        }
    });

    window.analytics.track('Comments on site', {
        'recipeId': $('#recipe_id').val()
    });

    ga('send', {
        hitType: 'event',
        eventCategory: 'All',
        eventAction: 'Comments on site',
        eventLabel: $('#recipe_id').val(),
        nonInteraction: false
    });
}

let commentCreateSubscribe = function(){
    if(typeof FB != 'object') {
        setTimeout(commentCreateSubscribe, 50);
        return;
    }
    FB.Event.subscribe('comment.create', SetFBCommentNotification);
};
document.addEventListener('DOMContentLoaded', commentCreateSubscribe);

document.addEventListener('DOMContentLoaded', function () {
    var addRecipeForm = $('.recipe-form-handler'),
        addThing = $('.add-things', addRecipeForm),
        rate = $(".rating"),
        GroupThingTpl = _.template('<ul class="striped FinishingStep" data-id="step_<%=step_number%>">' +
            '<li class="step-name clearfix">' +
            '<input name="data[FinishingStepTitle][<%=step_number%>][title]" type="text" class="skillet" placeholder=\'Add title (eg. "Finishing Steps - Stovetop","Finishing Steps - Grill")\'>' +
            '<a href="#"  onClick="return false" class="add-things with-image inner" data-thing="FinishingStep" data-placeholder="Add a finishing step">+ add another step</a>' +
            '<a href="#" onClick="return false" class="button close block">X</a>' +
            '</li>' +
            '<li>' +
            '<button class="image-preview">' +
            '<div class="image-view"></div>' +
            '<input type="file" class="upload-image" name="data[FinishingStepTitle][<%=step_number%>][FinishingStep][<%= count %>][image]" accept="image/x-png, image/gif, image/jpeg" data-view="block">' +
            '</button>' +
            '<textarea name="data[FinishingStepTitle][<%=step_number%>][FinishingStep][<%= count %>][text]" placeholder="Add a finishing step" class="skillet" required="required"></textarea>' +
            '</li>' +
            '<li class="unstriped clearfix">' +
            '</li>' +
            '</ul>'),

        imageTextFinishingThingTpl = _.template(
            '<li>'
            + '<button class="image-preview">'
            + '<div class="image-view"></div>'
            + '<input type="file" class="upload-image" name="data[FinishingStepTitle][<%=current_finishing%>][FinishingStep][<%= count %>][image]" accept="image/x-png, image/gif, image/jpeg" data-view="block">'
            + '</button>'
            + '<textarea name="data[FinishingStepTitle][<%=current_finishing%>][FinishingStep][<%= count %>][text]" placeholder="<%= placeholder %>" class="skillet" required="required"></textarea>'
            + '<a class="button close" href="javascript:void(0)">X</a>'
            + '</li>'),

        imageTextThingTpl = _.template(
            '<li>'
            + '<button class="image-preview">'
            + '<div class="image-view"></div>'
            + '<input type="file" class="upload-image" name="data[<%= thing %>][<%= count %>][image]" accept="image/x-png, image/gif, image/jpeg" data-view="block">'
            + '</button>'
            + '<textarea name="data[<%= thing %>][<%= count %>][text]" placeholder="<%= placeholder %>" class="skillet" required="required"></textarea>'
            + '<a class="button close" href="javascript:void(0)">X</a>'
            + '</li>'),

        textInputTpl = _.template(
            '<li>'
            + '<input name="data[<%= thing %>][<%= count %>][id]" type="hidden" value="">'
            + '<input name="data[<%= thing %>][<%= count %>][text]" placeholder="<%= placeholder %>" class="skillet form-error" type="text" required="required">'
            + '<a class="button close" href="javascript:void(0)">X</a>'
            + '</li>'
        ),

        flashMessageTpl = _.template('<div class="flash-message"><%= text %></div>'),
        readMore = _.template('<div class="toggle-view-btn">Read More</div>'),
        addVariantTpl = _.template('<div id="variation_<%= dataName %>" class="variant-data clearfix">' +
            '<h5><%= Title %></h5>' +
            '<input type="hidden" name="data[Recipe][VariationGroup][<%= dataName %>][title]" value=\'<%= Title %>\'>' +
            '<div class="field time clearfix"><h5>Time</h5>' +
            '<div class="half"><input name="data[Recipe][VariationGroup][<%= dataName %>][hours]" step="1" min="0" class="skillet" type="number" id="RecipeHours" required="required"><span>Hours</span></div>' +
            '<div class="half"><input name="data[Recipe][VariationGroup][<%= dataName %>][minutes]" step="1" min="0" class="skillet" type="number" id="RecipeMinutes" required="required"><span>Minutes</span></div>' +
            '</div>' +
            '<div class="field time clearfix">' +
            '<div class="field temperature">' +
            '<h5>Temp</h5>' +
            '<input name="data[Recipe][VariationGroup][<%= dataName %>][temperature]" class="skillet"  step="any" type="number" required="required">' +
            '<div class="input radio required">' +
            '<input type="hidden" name="data[Recipe][VariationGroup][<%= dataName %>][temperatureScale]">' +
            '<input type="radio" name="data[Recipe][VariationGroup][<%= dataName %>][temperatureScale]" id="RecipeTemperatureScaleC" value="C" required="required">' +
            '<label for="RecipeTemperatureScaleC">C</label>' +
            '<input type="radio" name="data[Recipe][VariationGroup][<%= dataName %>][temperatureScale]" id="RecipeTemperatureScaleF" value="F" required="required">' +
            '<label for="RecipeTemperatureScaleF">F</label>' +
            '</div></div></div>' +
            '<div class="field time clearfix">' +
            '<input  type="radio" name="data[Recipe][default_variation]" value="<%= dataName %>" id="default_<%= dataName %>" required="required" value="<%= dataName %>">' +
            '<label for="default_<%= dataName %>">default variation</label>' +
            '</div>' +
            '</div>');

    function addThingInput(event) {
        var elem = $(event['currentTarget']),
            data = elem.data(),
            target = $('.' + data.thing + ' li.unstriped', addRecipeForm),
            elementsCounter = $('.' + data.thing + '-count'),
            Tpl = '';

        if (elem.hasClass('inner')) {
            target = $('li.unstriped', $(elem.parents('ul')));
        }

        data['count'] = parseInt(elementsCounter.val()) + 1;
        data['step_number'] = $('.striped.FinishingStep').size();


        elementsCounter.val(data['count']);
        if (elem.hasClass('finishing')) {
            data['current_finishing'] = elem.parent().parent().data('id')
            Tpl = $(imageTextFinishingThingTpl(data));
        } else if (elem.hasClass('with-image')) {
            Tpl = $(imageTextThingTpl(data));
        } else if (elem.hasClass('with-new-block')) {

            target = $($('dd ul', elem.parents('dl')).last());
            Tpl = $(GroupThingTpl(data));

            target.after(Tpl);

            return;
        } else {
            Tpl = $(textInputTpl(data));
        }

        target.before(Tpl);
    }

    function removeItem(field) {
        var elem = $(field['currentTarget']);

        if (elem.hasClass('block')) {
            elem = $(elem).parents('ul');
        } else {
            elem = $(elem).parent();
        }

        elem.fadeOut();
        setTimeout(function () {
            elem.remove();
        }, 400);
    }

    //init tag mod plugins
    $(".multiple").select2();

    // add/delete thing in add recipe form
    addRecipeForm.on('click', '.add-things', addThingInput);
    addRecipeForm.on('click', '.button.close', removeItem);

    //submit add recipe form
    $('.recipe').on('submit', function () {
        $('#submitRecipe').prop('disabled', true);

        return true;
    });

    //mainPage category filter
    $('.category-filter.global select').change(function (e) {
        e.stopPropagation();
        e.preventDefault();
        if ($(this).val().length) {
            document.location.href = '/recipes/' + $(this).val();
        } else {
            document.location.href = '/';
        }
        return false;
    });

    //myRecipes category filter
    $('.category-filter.owner select').change(function (e) {
        e.stopPropagation();
        e.preventDefault();
        if (document.location.href.indexOf('searchString') >= 0) {
            document.location.href = '/user/' + $(e['currentTarget']).data('user')
                + '?searchString=' + $('#UserSearchString').val()
                + '&categorySlug=' + $(this).val();
        } else {
            document.location.href = '/user/' + $(e['currentTarget']).data('user') + '?categorySlug=' + $(this).val();
        }
        return false;
    });

    //myFavorites category filter
    $('.category-filter.favorite select').change(function (e) {
        e.stopPropagation();
        e.preventDefault();
        if (document.location.href.indexOf('searchString') >= 0) {
            document.location.href = '/users/favorites'
                + '?searchString=' + $('#UserSearchString').val()
                + '&categorySlug=' + $(this).val();
        } else {
            document.location.href = '/users/favorites?categorySlug=' + $(this).val();
        }
        return false;
    });

    //image preview
    function imagePreview(file, url, wrapper, errorElem, minWidth, maxSize, callback) {
        var input = file['currentTarget'], elemInput = $(file['currentTarget']),
            elem = $(input).parents('.image-preview'), errorElem = typeof errorElem !== 'undefined' ? errorElem : false;

        if (!errorElem) {
            errorElem = wrapper;
        }

        $('.flash-message', errorElem).remove();

        if (input.files && input.files[0]) {
            var reader = new FileReader;
            reader.onload = function (e) {
                var img = new Image;
                var posterInput = $('body').find('#poster__main .poster__input');

                img.src = e.target.result;
                img.onload = function () {
                    if (input.files[0].size < maxSize) {
                        if (img.width >= minWidth) {
                            if (url) {
                                callback(elemInput, url, wrapper, img.src, elemInput.attr('data-view'));
                                readImageFile(input);
                                showImageMessage(elemInput, '');
                            } else {
                                callback(elemInput.attr('data-view'), img.src, $('.image-view', elem[0]));
                                readImageFile(input);
                                showImageMessage(elemInput, '');
                            }

                            posterInput.hide();
                        } else {
                            if (elemInput.hasClass('query-upload-image')) {
                                elemInput.prev()
                                    .parent()
                                    .addClass('empty');
                            }
                            showImageMessage(elemInput, 'Please select a photo that is at least 710 pixels wide.');
                            elemInput.val('');
                        }
                    } else {
                        if (elemInput.hasClass('query-upload-image')) {
                            elemInput.prev()
                                .parent()
                                .addClass('empty');
                            posterInput.show();
                        }

                        showImageMessage(elemInput, 'Please select a photo that is less than 20 MB in size.');
                        elemInput.val('');
                    }
                }
            };

            reader.readAsDataURL(input.files[0]);
        }
    }

    function showImageMessage(element, message) {
        var el = $(element);
        var poster = el.closest('.poster-message');
        poster.attr('data-before', message);
    }

    function readImageFile(input) {
        if (input.files && input.files[0]) {
            var reader = new FileReader();

            reader.onload = function (e) {
                $(input).prev().attr('style', 'background-image: url(' + e.target.result + ')').removeClass('empty');
                $(input).attr('data-value-image', e.target.result);
            };

            reader.readAsDataURL(input.files[0]);
        }
    }

    function setImage(type, img, elem) {
        if (type == "block") {
            elem.attr('style', 'background-image: url("' + img + '");');
            elem.attr('data-url', img);
        } else if (type == "image") {
            elem.attr('src', img);
        }
    }

    function saveImage(input, url, wrapper, img, type) {
        var form = $(input.parents('form').get(0)), msg = $(flashMessageTpl({text: 'Please, try later!'})),
            ext = input.val().split('.').pop().toLowerCase();

        if (!wrapper) {
            wrapper = form;
        }

        $(".flash-message", wrapper).remove();

        if ($.inArray(ext, ['gif', 'png', 'jpg', 'jpeg']) == -1) {
            form.prepend(flashMessageTpl({'text': 'Wrong file type, please upload only image!'}));
        } else {
            wrapper.addClass('load');

            form.ajaxForm({
                url: url,
                type: 'POST',
                data: {
                    '': form.serialize(),
                    'ajax': 1
                },
                success: function (response) {
                    response = $.parseJSON(response);
                    if (response.status !== 'OK') {
                        msg = flashMessageTpl({text: response.msg});

                        wrapper.prepend(flashMessageTpl({text: response.msg}));
                    } else {
                        //@todo refactor this double image update
                        if (url.indexOf('recipes/edit') > 0) {
                            setImage(type, response.data, $('.img-responsive', form));
                            checkPublish();
                            progressStatus();
                        } else {
                            setImage(type, img, $('.img-responsive', form));
                        }

                        if (url == '/users/profile') {
                            setImage("block", img, $('.top-bar .avatar'));
                        }
                        $('.img-responsive', form).removeClass('empty');
                    }
                },
                error: function () {
                    wrapper.prepend(msg);
                },
                fail: function () {
                    wrapper.prepend(msg);
                },
                complete: function () {
                    wrapper.removeClass('load');
                }
            }).submit();
        }
    }

    function getVariationData(selectedList, recipeId) {
        $.ajax({
            url: '/recipes/getSelectedVariationSetup',
            type: 'POST',
            data: {
                'option': JSON.stringify(selectedList),
                'recipeId': recipeId
            },
            dataType: "json",
            success: function (response) {
                if (response.status !== 'OK') {
                    alert(response.msg);
                } else {
                    setVariationRecipeData(response.data, $('.variations-values'));
                    setVariationRecipeData(response.data, $('.sub-fields'));
                }
            }
        })
    }

    function setVariationRecipeData(variationSetupData, widget) {
        $('.variation-temp .data', widget).text(variationSetupData.temperature);
        $('.temp', widget).text(variationSetupData.temperature);
        $('.variation-time .data', widget).text(variationSetupData.time);
        $('.recipe_time', widget).text(variationSetupData.time);
    }

    $('.variations-widget select').on('change', function (event) {
        var elem = $(event['currentTarget']),
            wrapper = elem.parents('.variations-widget'),
            recipeId = wrapper.data('pk'),
            selectedList = [];

        $('select option:selected', wrapper).each(function () {
            if (!$.isEmptyObject($(this).data())) {
                selectedList.push($(this).data('option') + "");
            }
        });

        getVariationData(selectedList, recipeId)
    });

    addRecipeForm.on('change', '.add-image .upload-image', function (event) {
        imagePreview(event, false, $(event['currentTarget']).parents('.image-preview'), false, 710, 20447232, setImage);
    });
    // keep for testing
    // addRecipeForm.on('change', 'ul .upload-image', function (event) {
    //   imagePreview(event, false, $(event['currentTarget']).parents('.image-preview'), $(event['currentTarget']).parents('li'), 710, 20447232, setImage);
    // });
    addRecipeForm.on('submit', function () {
        $('#submitRecipe').prop('disabled', true);

        return true;
    });
    addRecipeForm.on('change', '.poster-recipe .upload-image', function (event) {
        var elem = $(event['currentTarget']);
        imagePreview(event, '/recipes/edit/' + elem.data('pk'), elem.parent(), false, 710, 20447232, saveImage);
    });
    addRecipeForm.on('change', '.poster.inline .upload-image', function (event) {
        var elem = $(event['currentTarget']);

        imagePreview(event, '/recipes/subedit/' + elem.data('pk'), elem.parent(), false, 710, 20447232, saveImage);
        elem.parents('form').removeClass('empty');
    });
    let avatarUpload = document.querySelector('.change-avatar .upload-image');
    if (avatarUpload) {
        avatarUpload.addEventListener('change', function (event) {
            imagePreview(event, '/users/profile', $('form'), false, 250, 20447232, saveImage);
        });
    }

    rate.rating().on('rating.change', function (event, value, caption) {
        var elem = $(event['currentTarget']),
            url = elem.data('url');

        $.ajax({
            url: url,
            data: JSON.stringify({'rating': parseInt(value, 10)}),
            dataType: "json",
            type: "POST",
            success: function (response, status) {
                if (status !== 'success') {
                    alert(response.msg);
                } else {
                    $('.star-count').text('(' + response.rating_count + ')');

                    window.analytics.track('Rates a recipe', {
                        'recipeId': $('#recipe_id').val(),
                        'rating': value
                    });

                    ga('send', {
                        hitType: 'event',
                        eventCategory: 'All',
                        eventAction: 'Rates a recipe',
                        eventLabel: 'RecipeID: ' + $('#recipe_id').val() + ' Rating: ' + value,
                        nonInteraction: false
                    });
                }

                $('.rating-action').rating('refresh', {showClear: false, showCaption: false});
                $('.rating-action').rating('update', response.rating);
                $('.summary-rate input.rating.form-control').rating('update', Math.round(response.average_rating));
            }
        });
    });

    rate.rating().on('rating.hoverleave', function (event, target) {
        var elem = $(event['currentTarget']);

        elem.rating('refresh', {showClear: false, showCaption: false});
    });

    if (navigator.userAgent.match(/(iPod|iPhone|iPad)/)) {
        $('.img-responsive.single').css('cursor', 'pointer');
    }

    let readMoreButton = $('.toggle-view-btn');
    let descriptionLines = $('.description-text').height() / parseFloat($(".description-text").css("line-height"));
    if (descriptionLines < 6) {
        readMoreButton.css('display', 'none');
    }

    if (readMoreButton) {
        readMoreButton.on('click', function (event) {
            event.currentTarget.parentElement.classList.toggle('show');
            if (event.currentTarget.parentElement.classList.contains('show')) {
                event.currentTarget.textContent = 'Read Less';
            } else {
                event.currentTarget.textContent = 'Read More';
            }
        });
    }

    

    $('.variants-list input[type="checkbox"]').on('click', function (event) {
        var variantList = $('.variants-list-data'), target = $(event["currentTarget"]),
            dataTarget = target.data(), variantsTitleList = [],
            getCheckboxState = target.context.checked;


        function getVariantsList() {
            var variantObj = [], checkedList = [],
                variantParent = '', temporaryArray = [];

            $('.variants-list input[type=checkbox]:checked').each(function (i) {
                if (!$.isEmptyObject($(this).data())) {
                    checkedList.push($(this).data());
                }
            });

            if (!$.isEmptyObject(checkedList)) {
                variantParent = checkedList[0]['parent'];
                for (var i = 0, j = 0; i < checkedList.length; i++) {
                    if ((variantParent != checkedList[i]['parent'])) {
                        variantObj[j] = temporaryArray;
                        temporaryArray = [];
                        j++;
                        variantParent = checkedList[i]['parent'];
                    }
                    temporaryArray.push(checkedList[i]);
                    if (i == checkedList.length - 1) {
                        variantObj[j] = temporaryArray;
                    }
                }

                return variantObj;
            }
        }


        function generatedVariantsChain(variantObj) {
            result = [];
            temp_array = [];
            for (var i = 0; i < variantObj.length; i++) {

                if (i == 0) {
                    for (k = 0; k < variantObj[i].length; k++) {
                        result.push([variantObj[i][k.toString()]]);
                    }

                } else {

                    temp_result = result.slice(0);
                    result.length = 0;
                    for (j = 0; j < temp_result.length; j++) {

                        for (k = 0; k < variantObj[i].length; k++) {
                            temp_array = temp_result[j].slice(0);
                            temp_array.push(variantObj[i][k]);
                            result.push(temp_array);


                        }

                    }
                }
            }
            return result;
        }

        if (target.hasClass('multicheck')) {
            if (getCheckboxState) {
                $('input[type="checkbox"]', target.parent()).prop("checked", true);
            } else {
                $('input[type="checkbox"]', target.parent()).prop("checked", false);
            }
        }

        if (typeof getVariantsList() !== 'undefined') {
            variantsObjList = generatedVariantsChain(getVariantsList());
            var cached_variants = getCachedVariants();
            variantList.html('<h4>Enter Time and Temperature</h4>');
            for (var i = 0; i < variantsObjList.length; i++) {
                variant_key = generateKey(variantsObjList[i]);
                container_id = 'variation_' + variant_key;
                if (typeof cached_variants != 'undefined' && typeof cached_variants[container_id] != 'undefined') {
                    variantList.append(cached_variants[container_id]);
                } else {
                    variantList.append(addVariantTpl({
                        'Title': generateTitle(variantsObjList[i]),
                        'dataName': variant_key
                    }));

                }
            }
        } else {
            variantList.html('');
        }

        function getCachedVariants() {
            var res = {};
            $.each($('.variants-list-data .variant-data'), function (index, value) {
                res[$(value).attr('id')] = value;
            });
            return res;
        }

        function generateTitle(objts) {
            res = '';
            for (var k = 0; k < objts.length; k++) {
                res += objts[k].title;
                if (k + 1 != objts.length) {
                    res += ' - ';
                }
            }
            return res;
        }

        function generateKey(objts) {
            res = [];
            for (var k = 0; k < objts.length; k++) {
                res.push(objts[k].id);

            }
            sorted = res.sort().slice(0);

            result = '';
            for (var k = 0; k < sorted.length; k++) {
                result += sorted[k];
                if (k + 1 != sorted.length) {
                    result += '-';
                }

            }

            return result;
        }
    });

    if ($('#footer').length) {
        var footerElem = $('#footer')[0];
        var footerElemOffsetTop = +footerElem.offsetTop;
        var footerElemClientHeight = +footerElem.clientHeight;
        var windowInnerHeight = +window.innerHeight;
        var footerTopMargin = 0;

        if ((footerElemOffsetTop + footerElemClientHeight) < windowInnerHeight) {
            footerTopMargin = windowInnerHeight - (footerElemOffsetTop + footerElemClientHeight);
            $('#footer').css('margin-top', footerTopMargin);
        }
    }

    $('.select_category').on('change', function () {
        var button = $('.modal-footer button');
        button.prop('disabled', false);
        button.css('background-color', '#ff8b01');
    }).on('submit', function (event) {
        event.preventDefault();
        var el = event.currentTarget;
        var url = $(el).attr('data-url');
        var id = $(el).attr('data-id');
        var button = $(this).find('button');
        var data = $(el).serialize();
        button.text('SAVING CATEGORY...');
        $.ajax({
            url: "/recipes/add_category/" + id,
            type: 'post',
            data: data,
            dataType: "json",
            success: function (response) {
                button.text('OPENING RECIPE...');
                window.location.replace(url);
            },
            fail: function (response) {
                // error handler
            }
        })
    })
});

import React, { useEffect } from "react";

const ShopifyCTA = ({ ctaVersion }) => {

  useEffect(() => {
    const anchorElement = document.querySelector(`.shopify_cta-${ctaVersion}`);

    if (anchorElement) {
      const href = anchorElement.getAttribute('href');
      const title = anchorElement.getAttribute('title');
      const currentPageUrl = window.location.href;

      if (typeof gtag === 'function') {
        gtag('event', 'view_recipe_backlink', {
          send_to: 'G-P30JN1M27B',
          property: 'Sous Vide Recipe Site',
          page: 'Collection Page',
          variant_id: `CTA Version ${ctaVersion}`,
          title: title,
          page_url: currentPageUrl,
          link_url: href
        });
      }
    }
  }, [ctaVersion]);

  const handleClick = (event) => {
    const { href, title } = event.currentTarget;
    const currentPageUrl = window.location.href;

    if (typeof gtag === 'function') {
      gtag('event', 'click_recipe_backlink', {
        send_to: 'G-P30JN1M27B',
        property: 'Sous Vide Recipe Site',
        page: 'Collection Page',
        variant_id: `CTA Version ${ctaVersion}`,
        title: title,
        page_url: currentPageUrl,
        link_url: href
      });
    }
  };

  const contentMap = {
    1: {
      title: 'Precision® Cooker Accessories',
      description: 'Experience next-level sous vide cooking.',
      href: 'https://anovaculinary.com/collections/accessories'
    },
    2: {
      title: 'Precision® Cookers',
      description: 'Edge-to-edge perfection. No skill required.',
      href: 'https://anovaculinary.com/pages/find-your-anova-precision-cooker'
    },
    3: {
      title: 'Precision® Cookers',
      description: 'Perfect results in a few simple steps.',
      href: 'https://anovaculinary.com/pages/find-your-anova-precision-cooker'
    },
    4: {
      title: 'Precision® Cookers',
      description: 'Sous vide made easier than ever.',
      href: 'https://anovaculinary.com/pages/find-your-anova-precision-cooker'
    },
    5: {
      title: 'Anova',
      description: 'Pro-level cooking techniques made simple.',
      href: 'https://anovaculinary.com/'
    }
  };

  const content = contentMap[ctaVersion] || {
    title: 'Anova',
    description: 'Pro-level cooking techniques made simple.',
    href: 'https://anovaculinary.com/'
  };

  return (
    <a
      href={content.href}
      target="_blank"
      className={`shopify_cta shopify_cta-${ctaVersion}`}
      title={`Shop ${content.title}`}
      onClick={handleClick}
    >
      <h3>{content.title}</h3>
      <p>{content.description}</p>
      <img
        src="/images/shop_anova_rating.png"
        alt="Precision® Cookers 5 Star Customer Rating"
      />
      <span>Shop Now</span>
    </a>
  );
};

export default ShopifyCTA;

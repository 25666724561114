import React, {Component} from "react";

class RecipeCard extends Component {
    /**
     * This is dependant on the feed structure
     * @param props
     */
    constructor(props) {
        super(props);
    }
    render () {
        // let posterStyle = {
        //     backgroundImage: "url(" + (this.props.recipe.RecipeFeed.image_small  || '/images/img-default-hero.png') + ")"
        // };
        let url = "/recipe/" + this.props.recipe.RecipeFeed.slug;
        let user_image = 'images/fancybox/blank.gif';
        let recipe_image = this.props.recipe.RecipeFeed.image_small || '';
        recipe_image = recipe_image.replace('s3.amazonaws.com/anovarecipes', 'd2n2qqypq504rb.cloudfront.net')
        .replace('v2.anovaculinary.com', 'anovaculinary.io');
        if (this.props.recipe.User.facebook_id) {
            user_image = "https://graph.facebook.com/" + this.props.recipe.User.facebook_id + "/picture?type=small"
        }
        if (this.props.recipe.User.image_small) {
            user_image = this.props.recipe.User.image_small;
        }
        user_image = user_image.replace('s3.amazonaws.com/anovarecipes', 'd2n2qqypq504rb.cloudfront.net').replace('v2.anovaculinary.com', 'anovaculinary.io');

        let ratings_url = "https://recipes-api.anovaculinary.com/v1/recipes/" + this.props.recipe.RecipeFeed.recipe_id + "/ratings";
        let user_name = this.props.recipe.User.username;
        if (this.props.recipe.User.first_name || this.props.recipe.User.last_name) {
            user_name = this.props.recipe.User.first_name + " " + this.props.recipe.User.last_name;
        }
        return (
            <figure className={"recipe_thumb " + this.props.recipe.RecipeFeed.recipe_id} key={this.props.recipe.RecipeFeed.recipe_id}>
                <a data-target="" data-toggle="" href={url} aria-label={this.props.recipe.RecipeFeed.name}>
                    <div className="recipe-poster lazy"
                         data-bg={"url(" + (recipe_image  || '/images/img-default-hero.png') + ")"} />
                </a>
                <figcaption className="clearfix">
                    <a data-target="" data-toggle="" href={url}>
                        <h4>{this.props.recipe.RecipeFeed.name}</h4>
                    </a>

                    <a className="author clearfix" href={"/user/"+this.props.recipe.User.slug}>
                        <div className="avatar lazy" data-bg={"url(" + user_image + ")"} />
                        <div className="author-data">
                            <h5>{user_name}</h5>
                            <h6>{this.props.recipe.User.location}</h6>
                        </div>
                    </a>
                    <div className="data">
                        <input type="number" className="rating form-control" data-min="0" data-max="5" data-step="1"
                               data-theme="krajee-uni" min="0" max="5" step="1"
                               defaultValue={this.props.recipe.RecipeFeed.avg_rating}  data-size="xs"
                               data-url={ratings_url} aria-label={"rating-"+this.props.recipe.RecipeFeed.name} />
                        <time>{this.props.recipe.RecipeFeed.cook_time}</time>
                    </div>
                </figcaption>
            </figure>
        );
    }
}
export default RecipeCard;

import React, {Component} from "react";
import RecipeCard from "./RecipeCard";
import ShopifyCTA from "./ShopifyCTA";

class RecipeList extends Component {
    constructor(props) {
      super(props);
      const randomNumber = Math.floor(Math.random() * 5) + 1;
      this.state = {
        ctaVersion: randomNumber
      };
    }
    componentDidMount() {
        // JQuery is not loaded attime of mount but this works.
        let ratings = document.querySelector('.rating') || []; // Star ratings need to be reloaded (jQuery)
        for (let i = 0; i < ratings.length; ++i) {
            ratings[i].rating('refresh');
        }
        if (typeof window.lazyLoadInstance != "undefined") {
            lazyLoadInstance.update();
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        // JQuery is loaded attime of updates
        $('.rating').rating('refresh');
        // React won't reset non-standard attributes on rerender. This attribute NEEDs to reset to rerender images.
        let lazies = document.getElementsByClassName('lazy');
        [].forEach.call(lazies, function(elem) {
            elem.setAttribute('data-was-processed', 'false');
        });
        if (typeof window.lazyLoadInstance != "undefined") {
            lazyLoadInstance.update();
        }
    }

    getRandomNumber = () => {
      const { ctaVersion } = this.state;
      const availableNumbers = [1, 2, 3, 4, 5].filter(
        (number) => number !== ctaVersion
      );
      const randomIndex = Math.floor(Math.random() * availableNumbers.length);
      const randomNumber = availableNumbers[randomIndex];
      return randomNumber;
    };

    render() {
      const { recipes } = this.props;
      const renderedComponents = recipes.map((recipe, index) => {
        const ctaVersion =
          (index + 1) === 5 ? this.getRandomNumber() : this.state.ctaVersion;

        return (
          <React.Fragment key={"recipe-" + recipe.RecipeFeed.recipe_id}>
            <RecipeCard recipe={recipe} />
            {(index + 1) === 5 && recipes.length >= 7 && <ShopifyCTA ctaVersion={ctaVersion} />}
            {(index + 1) === 13 && recipes.length >= 14 && <ShopifyCTA ctaVersion={ctaVersion} />}
          </React.Fragment>
        );
      });

      return <>{renderedComponents}</>;
    }
}

export default RecipeList;

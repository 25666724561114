'use strict';
import React from 'react';
import ReactDOM from 'react-dom';
import RecipeWidget from "./src/components/RecipeWidget";
import Pagination from "./src/components/Pagination";
import SearchOverride from "./src/components/SearchOverride";
import Page from "./src/components/Page";

if (![$, window.$, jQuery, $].every(function(elem) { return !!elem; })) {
    window.jQuery = window.$ = $ = jQuery = require("jquery");
}
if (typeof e === "undefined") {
    const e = React.createElement;
}
let g_filter_api = global.filter_api || '';
let g_paging = global.paging || {page:1};
let g_search = global.search || '';
let g_recipes = global.recipes || [];

// Update defaults.
Object.assign(Pagination.defaultProps, {
    apiUrl: g_filter_api,
    search: g_search,
});
Object.assign(SearchOverride.defaultProps, {
    apiUrl: g_filter_api,
    search: g_search,
});

Object.assign(RecipeWidget.defaultProps, {
    apiUrl: g_filter_api,
    search: g_search,
});

Object.assign(Page.defaultProps, {
    apiUrl: g_filter_api,
    paging: g_paging, // {page: 1, pageCount: pagesCount},
    search: g_search,
    recipes: g_recipes,
});

// This just triggers Page as a component to allow some state management, it's the state container.
if ( document.querySelector('#root')) {
    ReactDOM.render(<Page/>, document.querySelector('#root'));
}

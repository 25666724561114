import React, { Component } from 'react';

class RecipeWidget extends Component {
    constructor(props) {
        super(props);
        this.state = this.props.api.state;
    }

    widgets(segment, type="sort") {
        return this.state.widgets[segment] ? Object.keys(this.state.widgets[segment]).map((data, index) => {
            let active = '';
            if (this.state.api.state.sort === data) {
                active = ' active';
            }
            return (<div className={type + active} key={data}
                         onClick={() => {
                             if (data == index) {
                                 // This means it's a list
                                 return;
                             }
                             if (this.state.api.state.sort === data) {
                                 data = "";
                             }
                             this.state.api.updateStatus({sort: data});
                             this.state.api.fetchPage(this.state.api.recipesApiUrl(this.state.page));
                         }}>
                {this.state.widgets[segment][data]}
            </div>);
        }) : null;
    }
    render () {
        return (
            <div className="recipe-widgets">
                <div className="left-widgets">{this.state.widgets["left"] && <span className="widget-label">Sort by: </span>} {this.widgets("left", "sort")}</div>
                <div className="right-widgets">{this.widgets("right", "recipes-counter")}</div>
            </div>
        );
    }
}
RecipeWidget.defaultProps = {};
export default RecipeWidget;
import React, {Component} from "react";

class ChipMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.props.api.state,
            ...{
                chips: this.props.chips
            }
        };
        this.removeSearch =  this.removeSearch.bind(this);
    }
    removeChip(value, total_to_remove=1) {
        this.state.chips.splice(value, total_to_remove);
        this.updateStatus(this.props.chips)
    }
    updateStatus(value = []) {
        this.state.api.setState({
            checked: value
        }, () => {
            this.props.onRemoveChip()
        });
    }
    removeAllChips() {
        /**
         * Similar to the removeChip + updateStatus but brings in time. Reformatting could probably unify.
         */
        this.state.chips.splice(0, this.state.chips.length);
        this.state.api.setState({
            checked: this.props.chips,
            time: null
        }, () => {
            this.props.onRemoveChip()
        });
    }
    removeSearch() {
        this.state.api.setState(
            { search: null, },
            () => {
                this.props.onRemoveChip()
            });
    }
    categoryMap(categoryId) {
        return this.props.categories.find(function(category) {
            return category["value"] == categoryId;
        });
    }
    returnChip() {
        let chip = this.props.chips ? this.props.chips.map((data, index) =>
            <div className="chip-body" key={index}>
                <p className="chip-text">{this.categoryMap(data)["name"]}</p>
                <span className="chip-close"  onClick={e => this.removeChip(index)}>
                    <img src="images/chip-close.svg" className="add_circle" alt="&times;" /></span>
            </div>
        ) : [];
        if (this.state.api.state.time) {
            chip.push(
                <div className="chip-body" key="timeChip">
                    <p className="chip-text">{this.state.api.state.time + " hours"}</p>
                    <span className="chip-close" onClick={e => this.state.api.setState(
                        {time: null},
                        () => {
                            this.props.onRemoveChip()
                        })}>
                    <img src="images/chip-close.svg" className="add_circle" alt="&times;"/></span>
                </div>
            );
        }
        if (chip.length || this.props.api.state.search) {
            chip.push(<div className="chip-controls" key="controls">
                {(chip.length > 0) && <span onClick={e => this.removeAllChips()}>Clear all filters</span>}
                {(this.props.api.state.search) && <span onClick={e => this.removeSearch()}>Clear search</span>}
            </div>);
        }
        // // The wrong place/way to do this but I just want this done. Can refactor later.
        // if (this.props.api.state.search) {
        //     chip.push(<div className="chip-controls" key="controls">
        //         <span onClick={e => this.removeSearch()}>Clear search</span>
        //     </div>);
        // }
        return chip;
    }
    timeChip() {

    }
    render() {
        return(<div id="CHiPs" className="chip">{this.returnChip()}</div>);
    }
}
export default ChipMenu;
import React, {Component} from "react";

class Pagination extends Component {
    constructor(props) {
        super(props);
        this.state = this.props.api.state;
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.paging.page !== this.props.paging.page) {
            window.scrollTo(0, 0);
        }
    }

    returnList() {
        let current_page = this.props.paging.page;
        let config = {
            'first': 'First page ',
            'last': 'Last page ',
            'lock': true,
            'url': {'action': "$categorySlug", 'searchString': "$searchString"},
            'separator' : " | ",
        };
        // Replicating ->numbers() logic from cakephp
        let out = [];
        let modulus = 8;
        let half = (modulus / 2);
        let end = current_page + half;
        if (this.props.paging.pageCount === 1) {
            // If there's only one page we don't want pagination
            return [];
        }

        if (end > this.props.paging.pageCount) {
            end = this.props.paging.pageCount;
        }
        let start = current_page - (modulus - (end - current_page));
        if (start <= 1) {
            start = 1;
            end = current_page + (modulus - current_page) + 1;
        }
        let firstPage = Number.isInteger(config.first) ? config.first : 0;
        if (config.first && start > 1) {
            let offset = (start <= firstPage) ? start - 1 : config.first;
            out.push(this.returnPage(offset, config.first));
            if (firstPage >= start - 1) {
                out.push(config.separator);
            }
        }
        // Current page assignment happens in returnPage
        for (let i = start; i < end; i++) {
            out.push(this.returnPage(i));
            out.push(config.separator);
        }
        if (end !== current_page) {
            out.push(this.returnPage(end));
        }
        if (config.last && current_page < end) {
            let lastPage = Number.isInteger(config.last) ? config.last : this.props.paging.pageCount;
            let last_offset = (this.props.paging.pageCount < end + lastPage) ? this.props.paging.pageCount - end : config.last;
            if (!(last_offset <= lastPage && this.props.paging.pageCount - end > lastPage)) {
                out.push(config.separator);
            }
            out.push(this.returnPage(lastPage, config.last));
        }
        return out;
    }
    returnPage(pageNumber = 1, label = "") {
        let class_name = "";
        if (pageNumber === this.props.paging.page) {
            class_name = "current";
            return <span className={class_name} key={"page-"+(label || pageNumber)}>{label || pageNumber}</span>
        }
        return <span key={"page-"+(label || pageNumber)}><a onClick={() => this.props.api.fetchPage(this.props.api.recipesApiUrl(pageNumber))} lock="1" >{label || pageNumber}</a></span>
    }
    render () {
        return (
            this.returnList()
        );
    }
}

Pagination.defaultProps = {
    page: 1
};

export default Pagination;
import LazyLoad from "vanilla-lazyload";
window.lazyLoadOptions = {
    elements_selector: ".lazy"
};
// Listen to the initialization event and get the instance of LazyLoad
window.addEventListener('LazyLoad::Initialized', function (event) {
    window.lazyLoadInstance = event.detail.instance;
}, false);
window.addEventListener('load', function(event) {
    window.lazyLoadInstance = new LazyLoad(window.lazyLoadOptions);
}, false);
import React, {Component} from "react";
import ReactDOM from "react-dom";

class SearchOverride extends Component {

    constructor(props) {
        super(props);
        this.state = this.props.api.state;
        this.applySearch = this.applySearch.bind(this);
    }
    componentDidMount() {
        this.setState(this.props.api.state);
    }
    filter_str(str) {
        /**
         * Imitate htmlentities()
         */
        return String(str).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        this.inputEntry.value = this.props.search;
        if (this.props.search) {
            ReactDOM.render(
                <div className="search-header">Search results for {this.filter_str(this.props.api.state.search)}</div>,
                document.querySelector('#searchHeader')
            )
        } else {
            ReactDOM.render(
                <div />,
                document.querySelector('#searchHeader')
            )
        }
    }
    checkEnter(event) {
        if (event.key === "Enter") {
            this.applySearch(event);
        }
    }
    applySearch(event) {
        this.state.api.setState(
            {search: document.getElementById('RecipeSearchString').value},
            () => {
                this.props.onSelectOptions()
            });
        event.preventDefault();
    }
    render(){
        return(
            <div className="search">
                <input name="searchString" placeholder="Find Something Delicious..." className="search"
                       ref={el => this.inputEntry = el} aria-label="search-text"
                       defaultValue={this.props.search} onKeyPress={(e) => this.checkEnter(e)}
                       type="text" id="RecipeSearchString" />
                <button onClick={(e) => this.applySearch(e)} className="recipe-search" aria-label="search" name="search">
                    <div className="fake-button" />
                </button>
            </div>
        );
    }
}
SearchOverride.defaultProps = {};
export default SearchOverride;
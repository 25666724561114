let hamburger = document.querySelector('.toggle-topbar');
if(hamburger) {
    hamburger.addEventListener("click", function() {
        let nav = document.querySelector('nav.top-bar');
        nav.classList.toggle("expanded");
    })
}
let modal_add_recipe = document.querySelector('[data-target="#modalAddRecipe"]');
if (modal_add_recipe) {
    modal_add_recipe.addEventListener('click', function () {
        setTimeout(function () {
            let recipe_name_element = document.querySelectorAll('#RecipeName');
            recipe_name_element[0].focus();
        }, 1000);
    });
}